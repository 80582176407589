/* @import must be at top of file, otherwise CSS will not work */
/*@import url("//hello.myfonts.net/count/374929");*/


@font-face {
    font-family: "Brandon";
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url("../fonts/BrandonGrotesque-Thin.woff2");
}

@font-face {
    font-family: "Brandon";
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: url("../fonts/BrandonGrotesque-ThinItalic.woff2");
}

@font-face {
    font-family: "Brandon";
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url("../fonts/BrandonGrotesque-Light.woff2");
}

@font-face {
    font-family: "Brandon";
    font-style: italic;
    font-weight: 200;
    font-display: swap;
    src: url("../fonts/BrandonGrotesque-LightItalic.woff2");
}

@font-face {
    font-family: "Brandon";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url("../fonts/BrandonGrotesque-Regular.woff2");
}

@font-face {
    font-family: "Brandon";
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url("../fonts/BrandonGrotesque-RegularItalic.woff2");
}

@font-face {
    font-family: "Brandon";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("../fonts/BrandonGrotesque-Medium.woff2");
}

@font-face {
    font-family: "Brandon";
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url("../fonts/BrandonGrotesque-MediumItalic.woff2");
}


@font-face {
    font-family: "Brandon";
    font-style: oblique;
    font-weight: 600;
    font-display: swap;
    src: url("../fonts/BrandonGrotesque-Bold.woff2");
}

@font-face {
    font-family: "Brandon";
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url("../fonts/BrandonGrotesque-BoldItalic.woff2");
}

@font-face {
    font-family: "Brandon";
    font-style: oblique;
    font-weight: 900;
    font-display: swap;
    src: url("../fonts/BrandonGrotesque-Black.woff2");
}

@font-face {
    font-family: "Brandon";
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: url("../fonts/BrandonGrotesque-BlackItalic.woff2");
}

/*@font-face {*/
/*    font-family: "Caslon";*/
/*    font-style: italic;*/
/*    font-weight: 300;*/
/*    font-display: swap;*/
/*    src: url("../fonts/ACaslonPro-Italic.woff2");*/
/*}*/

@font-face {
    font-family: "Caslon";
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url("../fonts/ACaslonPro-SemiboldItalic.woff2");
}

@font-face {
    font-family: "Caslon";
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url("../fonts/ACaslonPro-BoldItalic.woff2");
}



