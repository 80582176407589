@import "variables.css";

.box-flex {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    margin: auto;
}

a.clean, .clean a {
    text-decoration: none;
}
a.clean:hover, .clean a:hover {
    text-decoration: underline;
}

a, a:active {
    outline: none;
}

.uppercase {
    text-transform: uppercase !important;
}

.menu-list {
    padding: 0;
    display: flex;
    list-style: none;
    justify-content: space-around;
    margin: 0;
}

.text-gray, .text-gray a, .text-gray a:hover {
    color: var(--gray-medium);
}
.text-gray a {
    text-decoration: none;
}
.text-gray a:hover {
    text-decoration: underline;
}

.cta-primary {
    background-color: var(--primary-color);
    text-decoration: none;
    text-align: center;
    color: white;
    padding: 1rem 1rem;
    border-radius: 6px;
}

.cta-normal {
    position: relative;
}

.cta-fixed {
    position: fixed;
    top: 56px;
    width: 100% !important;
    max-width: 100% !important;
}

.cta-fixed a {
    border-radius: 0;
}

.text-center {
    text-align: center !important;
}
.text-left {
    text-align: left !important;
}
.text-right {
    text-align: right !important;
}

.text-large {
    font-size: 1.563rem;
}

.text-larger {
    font-size: 2rem;
}

.text-largest {
    font-size: 3rem;
}

.text-small {
    font-size: 0.8rem;
}

.bg-gray {
    background-color: var(--gray-light);
}

.bg-primary {
    background-color: var(--primary-color);
    color: white;
}

.bg-secondary {
    background-color: var(--secondary-color);
}

.alt-font {
    font-family: Caslon, serif;
    font-style: italic;
    vertical-align: middle;
    font-size: 0.8em;
    padding-bottom: 0.2em;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

img.circle {
    border-radius: 300px;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    background-color: var(--secondary-color) !important;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
