@import "./assets/css/variables.css";
@import "./assets/css/fonts.css";

html {
    font-size: 16px;
}

body {
    margin: 0;
    font-family: "Brandon", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 100%;
}

.primary-color {
    color: var(--primary-color);
}

.bg-alt {
    background-color: #f5f5f5;
}

p {
    font-size: 1.563rem;
}

.live p {
    font-size: 1.14rem;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

nav {
    text-transform: uppercase;
    font-weight: bold;
}

nav a {
    text-decoration: none;
}

iframe {
    display: block;
    margin: auto;
}

img, video {
    width: 100%;
    height: auto;
}

strong {
    font-weight: 600;
}

a {
    color: var(--primary-color);
    text-decoration: none;
}

a:hover {
    color: var(--secondary-color);
}

.button-link {
    text-decoration: none;
}

.button-link:hover {
    color: var(--primary-color);
}

.invert a, .invert a:active, .inver a:visited {
    color: #ffffff;
}

h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 400;
}

h1 {
    font-size: 2.9rem;
}

h2 {
    font-size: 3.438rem;
}

h3 {
    font-size: 1.9rem;
}

h4 {
    font-size: 1.563rem;
}

h5 {
    font-size: 1.563rem;
    font-weight: 200;
}

h6 {
    font-size: 1rem;
}

.slick-slider .team-bio {
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
    /*display: none;*/
}

.MuiTabs-scroller .MuiButtonBase-root {
    font-size: 1.563rem !important;
}

.slick-slider .slick-current .team-bio {
    /*display: block;*/
    opacity: 1;
}



.box-flex {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    margin: auto;
}

.equipment-thumb {
    width: 180px;
    margin-left: auto;
    margin-right: auto;
}

.text-center {
    text-align: center !important;
}

.text-uppercase {
    text-transform: uppercase;
}

.videoFrame {
    position: relative;
    padding-bottom: 56.25%;
}

.videoFrame iframe, .videoFrame img, .videoFrame .videoContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
}

.StripeElement, .StripeElement input {
    display: block;
    margin: 10px 0 20px 0;
    max-width: 500px;
    padding: 10px 14px;
    font-size: 1em;
    font-family: "Source Code Pro", monospace;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    border: 0;
    outline: 0;
    border-radius: 4px;
    background: white;
}

input::placeholder {
    color: #aab7c4;
}


.StripeElement--focus {
    box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
}

/*.slick-prev::before, .slick-next::before {*/
/*  color: var(--primary-color) !important;*/
/*  opacity: 1 !important;*/
/*  font-size: 2rem !important;*/
/*}*/